import Button from '../Button';
import styles from './styles.module.css';
import Alert from '../Alert';

const ListModal = ({ isOpen, title, list, handleClick }) => {
  const tableList = Object.entries(list);
  return (
    <Alert isOpen={isOpen} closeButtonHandler={handleClick} hideButton={true}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.list}>
          {tableList.map((element) => (
            <div className={styles.listItem} key={element[0]}>
              <h1>{element[0]}</h1>
              <h1>{element[1]}</h1>
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <Button theme="red" customClass={styles.button} handleClick={handleClick}>
            Ok
          </Button>
        </div>
      </div>
    </Alert>
  );
};

export default ListModal;
