import React from 'react';
import cn from 'classnames';
import styles from './styles.module.css';

const Button = ({ theme, size, customStyles, customClass, handleClick, disabled, blockLevel, children }) => {
  return (
    <button
      onClick={handleClick}
      className={cn(styles.button, styles[`${size}Size`], styles[`${theme}Theme`], {[styles.block]: blockLevel}, customClass)}
      style={customStyles}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
