import {
  GET_PAYMENT_DATA,
  GET_PAYMENT_DATA_ERROR,
  GET_PAYMENT_DATA_SUCCESS,
} from '../actionTypes/payment-data-constant';
import axiosInstance from '../config/axiosConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export const getPaymentData = (paymentLinkId, coreApiServer) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PAYMENT_DATA });
      axiosInstance.defaults.headers['origin-full'] = coreApiServer;
      const { data: {clubspeedData} } = await axiosInstance({
        method: 'get',
        url: `payments/link?paymentLinkId=${paymentLinkId}`,
        headers: {}
      });
      dispatch({ type: GET_PAYMENT_DATA_SUCCESS, payload: clubspeedData });
      return dispatch(getPaymentCheckData(clubspeedData.checkId, coreApiServer));
    } catch (error) {
      return dispatch({ type: GET_PAYMENT_DATA_ERROR, payload: false });
    }
  };
};

export const getPaymentCheckData = (checkId, coreApiServer) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PAYMENT_DATA });

      axiosInstance.defaults.headers['origin-full'] = coreApiServer;
      const { data: { clubspeedData } } = await axiosInstance({
        method: 'get',
        url: `payments/check?checkId=${checkId}`,
        headers: {}
      });

      const { data } = await axiosInstance({
        method: 'get',
        url: 'CoreApi/ControlPanelSettings?SettingName=ClubKioskEnableClubSpeedConvenienceFee&TerminalName=MainEngine',
        headers: {},
      });

      return dispatch({
        type: GET_PAYMENT_DATA_SUCCESS,
        payload: {
          checkRemainingTotal: clubspeedData?.checkRemainingTotal,
          subtotals: clubspeedData?.checkSubtotal,
          taxes: clubspeedData?.checkTax || 0,
          convenienceFees: clubspeedData?.checkClubSpeedKioskFeeTotal || 0,
          total: clubspeedData?.checkTotal + clubspeedData?.checkClubSpeedKioskFeeTotal || 0,
          isConvenienceFeeEnabled: Boolean(JSON.parse(data?.clubspeedData?.[0]?.settingValue || false)),
          amount: clubspeedData?.checkTotalPlusKioskFees || 0,
        },
      });
    } catch (e) {
      return dispatch({ type: GET_PAYMENT_DATA_ERROR, payload: false });
    }
  };
};
