import {
  GET_PAYMENT_DATA,
  GET_PAYMENT_DATA_SUCCESS,
  GET_PAYMENT_DATA_ERROR
} from '../actionTypes/payment-data-constant';

const INITIAL_STATE = {
  paymentLinkId: null,
  checkId: null,
  amount: null,
  expirationDate: null,
  headerLogoBase64String: '',
  linkSourceId: null,
  isPaid: false,
  createdDate: null,
  stripeSettings: null,
  cardConnectSettings: null,
  checkRemainingTotal: null,
  subtotals: 0,
  taxes: 0,
  total: 0,
  convenienceFees: 0,
  isConvenienceFeeEnabled: false,
};

const paymentDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENT_DATA:
    {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_PAYMENT_DATA_SUCCESS:
    {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    }
    case GET_PAYMENT_DATA_ERROR:
    {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default paymentDataReducer;
