import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getPaymentData } from '../../action/payment-data-action';
import ExpiredPayment from '../ExpiredPayment';
import SuccessfulPayment from '../SuccessfulPayment';
import PaymentFormStripe from '../PaymentFormStripe';
import PaymentFormCardConnect from '../PaymentFormCardConnect';
import mainLogo from '../../assets/img/logoPaymentPortal.svg';
import './Payment.css';

const MakePayment = () => {
  const dispatch = useDispatch();
  const { linkData } = useParams();

  const { paymentLinkId, coreApiServer } = JSON.parse( window.atob(decodeURIComponent(linkData)));

  const {
    paymentData:{
      total,
      subtotals,
      taxes,
      convenienceFees,
      isConvenienceFeeEnabled,
      cardConnectSettings,
      expirationDate,
      checkId,
      isLoading,
      isPaid,
      stripeSettings,
      checkRemainingTotal,
    },
    makePayment,
  } = useSelector(state => state);

  const isNotExpired = !(moment() > moment.utc(expirationDate).toDate());
  const expirationDays = moment(expirationDate).diff(moment(), 'days');

  React.useEffect(()=> {
    dispatch(getPaymentData(paymentLinkId, coreApiServer, checkId));
  }, []);

  return (
    <>
      <div className="main">
        <div className="main-header">
          <div className="logo">
            <img src={mainLogo} />
          </div>
          {(!isPaid && !makePayment.isSessionSuccess) && <>
            <h2 className="mainTitle">Almost There!</h2>
            <h3 className="mainSubTitle">
              Complete your payment or deposit using this secure form
            </h3>
          </>}
        </div>
        <div className="main-container">
          {
            !isLoading && (
              <div className="main-content__inner">
                {
                  (!isNotExpired && !isPaid || checkRemainingTotal <= 0 && !isPaid) && (
                    <ExpiredPayment />
                  )
                }
                {
                  makePayment.isSessionSuccess && !isPaid && (
                    <SuccessfulPayment title="Thank you!">
                      <h6>We&apos;ve emailed you the receipt.</h6>
                    </SuccessfulPayment>
                  )
                }
                {
                  isPaid && (
                    <SuccessfulPayment title="Good news!">
                      <h6>You&apos;ve already paid for your purchase.</h6>
                      <h6>You&apos;re good to go!</h6>
                    </SuccessfulPayment>
                  )
                }
                {
                  (!isPaid && stripeSettings && isNotExpired && checkRemainingTotal > 0) && (
                    <PaymentFormStripe />
                  )
                }
                {
                  (!isPaid && !makePayment.isSessionSuccess && cardConnectSettings && isNotExpired && checkRemainingTotal > 0) && (
                    <PaymentFormCardConnect
                      paymentLinkId={paymentLinkId}
                      amount={total}
                      subtotals={subtotals}
                      taxes={taxes}
                      convenienceFees={convenienceFees}
                      isConvenienceFeeEnabled={isConvenienceFeeEnabled}
                      checkId={checkId}
                      cardConnectSettings={cardConnectSettings}
                      expirationDays={expirationDays}
                      errorMessage={makePayment.errorMessage}
                      isLoading={makePayment.isLoading}
                      coreApiServer={coreApiServer}
                    />
                  )
                }
              </div>
            )
          }
        </div>
      </div>
      {
        isLoading && (
          <div className="loading">
            <div className="loader"/>
          </div>
        )
      }
    </>
  );
};

export default MakePayment;
