import React from 'react';
import { useDispatch } from 'react-redux';
import queryString from '../../utils/queryString';
import {makeCardConnectPayment, resetErrorPaymentMessage } from '../../action/make-payment-action';
import Alert from '../Alert';
import './form.css';
import { getPaymentCheckData } from '../../action/payment-data-action';
import axiosInstance from '../../config/axiosConfig';
import moment from 'moment';
import ListModal from '../ListModal';

const PaymentFormCardConnect = ({paymentLinkId, coreApiServer, amount, subtotals, taxes, convenienceFees, isConvenienceFeeEnabled, cardConnectSettings, expirationDays, checkId, errorMessage, isLoading}) => {
  const dispatch = useDispatch();
  const initialForm = {
    cvv2: '',
    postal: '',
  };

  const [loading, setLoading] = React.useState(false);
  const [validationErrorAlert, setValidationErrorAlert] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [fieldsOfForm, setFieldsOfForm] = React.useState(initialForm);
  const [iframeIsLoaded, setIframeIsLoaded] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if(errorMessage) {
      setIndex(index + 1);
    }
  }, [errorMessage]);

  const onChangeInputValue = (e) => {
    setFieldsOfForm({ ...fieldsOfForm, [e.target.name]: e.target.value });
  };

  const cssStyle = '.error%7Bcolor%3A%23E10808%3Bborder-color%3A%23E10808%3B%7D\n' +
      'label%7B\n' +
      'font-weight%3A%20bold%3B\n' +
      '%20%20%20%20margin-bottom%3A%2010px%3B\n' +
      '%20%20%20%20display%3A%20inline-block%3B\n' +
      'color%3A%23000000%3B\n' +
      'font-family%3A%20-apple-system%2CBlinkMacSystemFont%2C%22Segoe%20UI%22%2CRoboto%2C%22Helvetica%20Neue%22%2CArial%2C%22Noto%20Sans%22%2C%22Liberation%20Sans%22%2Csans-serif%2C%22Apple%20Color%20Emoji%22%2C%22Segoe%20UI%20Emoji%22%2C%22Segoe%20UI%20Symbol%22%2C%22Noto%20Color%20Emoji%22%3B\n' +
      '%7D\n' +
      'input%7B\n' +
      'padding%3A%205px%3B\n' +
      'width%3A50%25%3B\n' +
      'font-size%3A18px%21important%3B\n' +
      'height%3A50px%3B\n' +
      'padding%3A.5rem1rem%3B\n' +
      'line-height%3A1.5%3B\n' +
      'border-radius%3A8px%3B\n' +
      'display%3Ablock%3B\n' +
      'font-weight%3A400%3B\n' +
      'color%3A%23495057%3B\n' +
      'background-color%3A%23F4F4F4%3B\n' +
      'background-clip%3Apadding-box%3B\n' +
      'border%3Anone%3B\n' +
      'overflow%3Avisible%3B\n' +
      'margin%3A0%3B\n' +
      'font-family%3A%20-apple-system%2CBlinkMacSystemFont%2C%22Segoe%20UI%22%2CRoboto%2C%22Helvetica%20Neue%22%2CArial%2C%22Noto%20Sans%22%2C%22Liberation%20Sans%22%2Csans-serif%2C%22Apple%20Color%20Emoji%22%2C%22Segoe%20UI%20Emoji%22%2C%22Segoe%20UI%20Symbol%22%2C%22Noto%20Color%20Emoji%22%3B\n' +
      '%7D\n' +
      'select%7B\n' +
      'padding%3A%205px%3B\n' +
      'width%3A100px%3B\n' +
      'font-size%3A18px%21important%3B\n' +
      'height%3A50px%3B\n' +
      'padding%3A.5rem1rem%3B\n' +
      'line-height%3A1.5%3B\n' +
      'border-radius%3A8px%3B\n' +
      'display%3Ainline-block%3B\n' +
      'font-weight%3A400%3B\n' +
      'color%3A%23495057%3B\n' +
      'background-color%3A%23F4F4F4%3B\n' +
      'background-clip%3Apadding-box%3B\n' +
      'border%3Anone%3B\n' +
      'overflow%3Avisible%3B\n' +
      'margin%3A0%3B\n' +
      'margin-bottom%3A%2020px%3B\n' +
      'font-family%3A%20-apple-system%2CBlinkMacSystemFont%2C%22Segoe%20UI%22%2CRoboto%2C%22Helvetica%20Neue%22%2CArial%2C%22Noto%20Sans%22%2C%22Liberation%20Sans%22%2Csans-serif%2C%22Apple%20Color%20Emoji%22%2C%22Segoe%20UI%20Emoji%22%2C%22Segoe%20UI%20Symbol%22%2C%22Noto%20Color%20Emoji%22%3B\n' +
      '%7D\n' +
      '%23ccnumfield%7B\n' +
      'width%3A98%25%3B\n' +
      '%7D';

  const params = queryString({
    // usecvv: 'true',
    // invalidcvvevent: 'true',
    useexpiry: 'true',
    invalidexpiryevent: 'true',
    unique: 'true',
  });

  const www = `${cardConnectSettings.cardConnectURL}`;

  const iFrameUrl = `https://${www}/itoke/ajax-tokenizer.html?css=${cssStyle}&${params}`;


  const handleTokenEvent = () => {
    window.addEventListener(
      'message',
      (event) => {

        if (event.origin === `https://${www}`) {
          const eventData = JSON.parse(event.data);

          if (eventData.validationError) {
            return sessionStorage.setItem('validationError', eventData.validationError);
          }

          sessionStorage.setItem('validationError', '');
          sessionStorage.setItem('emvToken', eventData.message ? eventData.message : '');
          sessionStorage.setItem('expiryDate', eventData.expiry ? moment(eventData.expiry.slice(0, 4) + '-' + eventData.expiry.slice(4)).format('MMYY') : '');
        }


      },
      false,
    );
    setIframeIsLoaded(true);
  };

  const payHandler = async () => {

    try {
      if(loading) {
        return;
      }
      setLoading(true);

      if(!fieldsOfForm.cvv2) {
        sessionStorage.setItem('validationError', 'CVV is a required field');
        setLoading(false);
        return setValidationErrorAlert(true);
      }

      if(!fieldsOfForm.postal) {
        sessionStorage.setItem('validationError', 'Postal Code is a required field');
        setLoading(false);
        return setValidationErrorAlert(true);
      }

      axiosInstance.defaults.headers['origin-full'] = coreApiServer;
      const { data: { clubspeedData: { checkRemainingTotal } } } = await axiosInstance({
        method: 'get',
        url: `payments/check?checkId=${checkId}`,
        headers: {}
      });

      const validationError = sessionStorage.getItem('validationError');
      const emvToken = sessionStorage.getItem('emvToken');
      const expiryDate = sessionStorage.getItem('expiryDate');

      if(validationError) {
        return setValidationErrorAlert(true);
      }

      if(checkRemainingTotal <= 0) {
        dispatch(getPaymentCheckData(checkId, coreApiServer));
      }

      if(emvToken && paymentLinkId && expiryDate && checkRemainingTotal > 0) {
        dispatch(makeCardConnectPayment(emvToken, paymentLinkId, checkId, expiryDate, fieldsOfForm.cvv2, fieldsOfForm.postal));
      }

      setFieldsOfForm(initialForm);
      sessionStorage.setItem('validationError', '');
      sessionStorage.setItem('emvToken', '');
      sessionStorage.setItem('expiryDate', '');
      setLoading(false);

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="form-container">
        <div className="form-container__inner">
          <form name="tokenform" id="tokenform">
            <iframe cvvlabel="Custom CVV Label"
              id="tokenframe"
              key={index}
              name="tokenframe"
              src={iFrameUrl}
              frameBorder="0"
              scrolling="no"
              width="100%"
              height="210"
              onLoad={handleTokenEvent}/>
            {iframeIsLoaded &&
            <>
              <div className="form-field">
                <label htmlFor="cvv2">CVV</label>
                <input type="text" name="cvv2" id="cvv2" pattern="\d+" maxLength="4" required onChange={onChangeInputValue} value={fieldsOfForm.cvv2} />
              </div>
              <div className="form-field">
                <label htmlFor="postal">Postal Code</label>
                <input type="text" name="postal" id="postal" required onChange={onChangeInputValue} value={fieldsOfForm.postal} />
              </div>
            </>}
          </form>
          {
            isConvenienceFeeEnabled ? (
              <div className="amount-info clickable-button" onClick={() => setShowModal(true)}>
                <div className="amount-info__title">Taxes & Fees</div>
                <div className="amount-info__value">${(convenienceFees + taxes).toFixed(2)}</div>
              </div>
            ) : (
              <div className="amount-info">
                <div className="amount-info__title">Taxes</div>
                <div className="amount-info__value">${taxes.toFixed(2)}</div>
              </div>
            )
          }
          <div className="amount-info">
            <div className="amount-info__title">Subtotal</div>
            <div className="amount-info__value">${subtotals.toFixed(2)}</div>
          </div>
          <div className="amount-info">
            <div className="amount-info__title">Total</div>
            <div className="amount-info__value">${amount.toFixed(2)}</div>
          </div>
          <button className="pay-button" onClick={payHandler}>Pay ${amount.toFixed(2)}</button>

          <div className="expire-info">
            <span>This link will expire in {expirationDays} { expirationDays > 1 ? 'days' : 'day' }</span>
          </div>
        </div>
      </div>
      <Alert isOpen={validationErrorAlert} closeButtonHandler={()=> setValidationErrorAlert(false)}>
        <div className="error-wrap">
          <h4>Uh-oh!</h4>
          <p>{sessionStorage.getItem('validationError')}</p>
        </div>
      </Alert>
      <Alert isOpen={!!errorMessage} closeButtonHandler={()=> dispatch(resetErrorPaymentMessage())}>
        <div className="error-wrap">
          <h4>Uh-oh!</h4>
          <p>{errorMessage}</p>
        </div>
      </Alert>
      {
        (loading || isLoading) && (
          <div className="loading loading-light">
            <div className="loader"/>
          </div>
        )
      }
      <ListModal
        zIndex={9999}
        isOpen={showModal}
        title="Taxes & Fees"
        list={{
          Taxes: `$ ${taxes.toFixed(2)}`,
          Fees: `$ ${convenienceFees.toFixed(2)}`,
        }}
        handleClick={() => setShowModal(false)}
      />
    </>
  );
};

export default PaymentFormCardConnect;
