import React from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const Alert = ({ isOpen, closeButtonHandler, hideButton, children }) => {
  return (
    <ReactModal isOpen={isOpen} className="modal" overlayClassName="modal-overlay">
      <div className="modal-container">
        {children}
        {!hideButton && (
          <button onClick={closeButtonHandler} className="modal-btn">
          OK
          </button>
        ) }
      </div>
    </ReactModal>
  );
};

export default Alert;
