import {
  MAKE_CARD_CONNECT_PAYMENT,
  MAKE_CARD_CONNECT_PAYMENT_ERROR,
  MAKE_CARD_CONNECT_PAYMENT_SUCCESS,
  RESET_ERROR_PAYMENT_MESSAGE
} from '../actionTypes/make-payment-constant';
import axiosInstance from '../config/axiosConfig';

export const makeCardConnectPayment = (token, paymentLinkId, checkId, cardExpirationDate, cvv2, postal) => {
  return async (dispatch) => {
    try {
      dispatch({type: MAKE_CARD_CONNECT_PAYMENT});

      const {data: {clubspeedData}} = await axiosInstance({
        method: 'post',
        url: 'payments/pay/cc',
        data: {
          token,
          paymentLinkId,
          cardExpirationDate,
          checkId: checkId,
          kdsRequestItems: null,
          cvv: cvv2,
          postalCode: postal,
          isText2Pay: true
        }
      });
      if (clubspeedData.errorMessage) {
        return dispatch({type: MAKE_CARD_CONNECT_PAYMENT_ERROR, payload: clubspeedData});
      }
      return dispatch({type: MAKE_CARD_CONNECT_PAYMENT_SUCCESS, payload: clubspeedData});
    } catch (error) {
      return dispatch({type: MAKE_CARD_CONNECT_PAYMENT_ERROR, payload: false});
    }
  };
};

export const resetErrorPaymentMessage = () => {
  return async (dispatch) => {
    dispatch({type: RESET_ERROR_PAYMENT_MESSAGE});
  };
};

export const makeStripePayment = () => {
};
